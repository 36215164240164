<template>
	<div class="block sm:flex sm:gap-10 mt-10 sm:mt-20">
		<!-- About me portfolio image start -->
		<div class="w-full sm:w-1/4 mb-7 sm:mb-0">
			<img
				src="@/assets/images/developer.svg"
				class="rounded-xl w-96"
				alt=""
			/>
		</div>
		<!-- About me portfolio image end -->

		<!-- About me details start -->
		<div class="w-full sm:w-3/4 text-left">
			<p
				v-for="bio in bios"
				:key="bio.id"
				class="mb-4 text-ternary-dark dark:text-ternary-light text-lg"
			>
				{{ bio.bio }}
			</p>
		</div>
		<!-- About me details end -->
	</div>
</template>

<script>
export default {
	setup() {
		return {
			bios: [
				{
					id: 1,
					bio:
						'Even voorstellen, mijn naam is Nav Appaiya en ik ben een PHP Developer uit Spijkenisse. Ik ben dol op het schrijven van PHP-code voor het web en kan mijn weg goed vinden in PHP, HTML5, JS, CSS3 en/of werk graag in frameworks zoals Laravel, Symfony, of met ReactJS.',
				},
				{
					id: 2,
					bio:
						'Ik werk graag als freelancer en dit doe ik al sinds 2017 naast mijn vaste baan als PHP-Developer. Ik ben goed met PHP, Symfony, Laravel en Wordpress. Ook ligt mijn voorkeur bij E-commerce en vind ik het leuk om shops te beheren en optimaliseren tot het uiterste.',
				},
				{
					id: 3,
					bio:
						'Officieel ben ik actie op het internet sinds 2000, en heb ik nog inbellen via telefoonlijn meegemaakt. Ook was ik erbij toen webapplicaties werden ontwikkeld, ik ben sinds 2014 lid van Gitlab onder de username Nav (Gitlab bestaat sinds 2013 ongeveer)',
				},
				{
					id: 4,
					bio:
						'This helping attitude helped me develop and grow a steady group of satisfied customers and friends, who saw me as that kid that is good with computers. I started on windows, and eventually moved up to the macOS for computing which allowed me to grow even faster and becoming better at development of software for the web.',
				},
			],
		};
	},
};
</script>
