<template>
	<div class="mt-10 sm:mt-20">
		<p
			class="text-2xl sm:text-3xl text-primary-dark dark:text-primary-light font-sans font-semibold"
		>
			{{ clientsHeading }}

    </p>
		<div class="grid grid-cols-6 sm:grid-cols-6 mt-10 sm:mt-20 gap-2">

			<AboutClientSingle
				v-for="client in clients"
				:key="client.id"
				:client="client"
			/>
		</div>
	</div>
</template>

<script>
import AboutClientSingle from './AboutClientSingle.vue';
import axios from "axios"
console.log('test');
export default {
	components: {
		AboutClientSingle,
	},
	data: () => {
		return {
			clientsHeading: 'Een paar bedrijven waar ik voor of samen mee heb gewerkt',
      clients: [
				{
					id: 1,
					title: 'Jokasport.nl',
					img: require('@/assets/images/brands/jokasport.png'),
				},
				{
					id: 2,
					title: 'Postcode.nl',
					img: require('@/assets/images/brands/postcodenl-logo-blauw.png'),
				},
				{
					id: 3,
					title: 'Exact.com',
					img: require('@/assets/images/brands/exact.png'),
				},
				{
					id: 4,
					title: 'Meprint.nl',
					img: require('@/assets/images/brands/meprint.png'),
				},
				{
					id: 5,
					title: 'Rikkimode.nl',
					img: require('@/assets/images/brands/rikkimode.png'),
				},
				{
					id: 6,
					title: 'Sports-supplier.nl',
					img: require('@/assets/images/brands/sports_supplier.svg'),
				}
			],
		};
	},
  methods: {
    // Helper function for extracting a nested image object
    extractImage(post) {
      const defaultImg = {
        url: "http://placehold.it/210x140?text=N/A",
        caption: post.title,
      }
      if (!post.multimedia) {
        return defaultImg
      }
      let imgObj = post.multimedia.find(
          media => media.format === "mediumThreeByTwo210"
      )
      return imgObj ? imgObj : defaultImg
    },
    async fetchNews() {
      try {
        const url = `https://api.github.com/users/nav-appaiya`
        const response = await axios.get(url);
        const results = response.data.results;
        this.posts = results;
      } catch (err) {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log("Server Error:", err)
        } else if (err.request) {
          // client never received a response, or request never left
          console.log("Network Error:", err)
        } else {
          console.log("Client Error:", err)
        }
      }
    },
    mounted() {
      this.fetchNews()
    },

  },

};
</script>
